import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Logo from "../images/ISG grip.png";
import SunPharma from "../images/sunLogo.png";
import Sygnus from "../images/sygnus.png";
import logo1 from "../images/IsgLogo1.png";

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      const trimmedEmail = email.trim();

      if (!trimmedEmail) {
        toast.error("Please enter email", { duration: 2000 });
        return;
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailPattern.test(trimmedEmail)) {
        toast.error("Please enter a valid email", { duration: 2000 });
        return;
      }

      if (!password.trim()) {
        toast.error("Please enter password", { duration: 2000 });
        return;
      }
      const { data } = await axios.post(
        "https://isggrip.digilateral.com/api/adminLogin",
        {
          email,
          password,
        }
      );
      toast.success(data.message);
      localStorage.setItem("adminToken", data.token);
      setTimeout(() => {
        navigate("/dashboard");
        setEmail("");
        setPassword("");
      }, 0);
    } catch (error) {
      // console.log(error)
      toast.error(error.response.data.message, { duration: 2000 });
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-orange-400">
      <Toaster />
      <div className="border border-gray-300 shadow-md rounded-lg max-w-md w-full p-6 bg-white">
        <div className="flex justify-end">
          <img src={logo1} alt="logo" width="60px" height="60px" />
        </div>
        <div>
          <div className="flex justify-center p-4">
            <img src={Logo} alt="logo" width="190px" height="190px" />
          </div>
          <div class="mb-8">
            <label
              // for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              // placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div class="mb-8">
            <label
              // for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Password<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              // placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {/*
          <div className="flex justify-center">
            <button
              onClick={handleLogin}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Login
            </button>
          </div>
          <div className="flex items-center w-full justify-center  pt-10 font-mono gap-4">
            <p className="w-10 text-[11px] font-medium mt-10 text-center">Academic Partner</p>
            <img src={Sygnus} alt="" width="80px" height="80px" />
          </div>
	  */}

          <div className="flex justify-center">
            <button
              onClick={handleLogin}
              type="submit"
              className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Login
            </button>
          </div>
          <div className="flex items-center w-full justify-center  pt-10 font-mono gap-[3px] mr-8">
            <p
              className="text-[11px] mt-6 text-center italic font-sans"
              style={{ fontFamily: "sans-serif" }}
            >
              Academic partner
            </p>
            <img
              src={Sygnus}
              alt=""
              className="mr-8"
              width="50px"
              height="50px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
