import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Logo from "../images/ISG grip.png";
import toast, { Toaster } from "react-hot-toast";

const Dashboard = () => {
  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editUserData, setEditUserData] = useState({});

  const [delteId, setDeleteId] = useState("");

  const [duplicate, setDuplicate] = useState("");

  const openDeleteConfirmationModal = (e) => {
    setDeleteId(e);
    setShowDeleteModal(true);
  };

  const closeDeleteConfirmationModal = () => {
    setShowDeleteModal(false);
  };

  const openEditDialog = (user) => {
    // console.log(user)
    setEditUserData(user);
    setShowEditDialog(true);
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
    setEditUserData({});
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const adminToken = localStorage.getItem("adminToken");
        if (adminToken) {
          const response = await axios.get(
            "https://isggrip.digilateral.com/api/getUsers"
          );
          setUserData(response.data.data.reverse());
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const filteredUserData = userData.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.place.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.mobileNo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to check for duplicates and set the state variable
  useEffect(() => {
    let duplicateCount = 0;
    filteredUserData.forEach((user, index) => {
      const isDuplicate =
        filteredUserData.findIndex(
          (otherUser, otherIndex) =>
            (otherUser.email === user.email ||
              otherUser.mobileNo === user.mobileNo) &&
            otherUser._id !== user._id && // Exclude the current user from comparison
            otherIndex > index
        ) !== -1;

      if (isDuplicate) {
        duplicateCount++;
      }
    });
    setDuplicate(duplicateCount);
  }, [filteredUserData]);

  const handleDelete = async () => {
    console.log(delteId);
    try {
      await axios.delete(
        `https://isggrip.digilateral.com/api/delteUser/${delteId}`
      );
      toast.success("Entry Deleted success");
      setUserData(userData.filter((user) => user._id !== delteId));
      closeDeleteConfirmationModal();
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handleEdit = async (data) => {
    try {
      const requestData = {
        name: data.name,
        email: data.email,
        mobileNo: data.mobileNo,
        place: data.place,
      };
      const response = await axios.put(
        `https://isggrip.digilateral.com/api/editUser/${data._id}`,
        requestData
      );

      if (response.status === 200) {
        // Update userData state after successful editing
        setUserData(
          userData.map((user) => {
            if (user._id === data._id) {
              return {
                ...user,
                name: data.name,
                email: data.email,
                mobileNo: data.mobileNo,
                place: data.place,
              };
            }
            return user;
          })
        );

        // Show success message
        toast.success("Updated Successfully");

        closeEditDialog();
      } else {
        console.error("Unexpected response status:", response.status);
        toast.error("Failed to update user. Please try again later.");
      }
    } catch (error) {
      // Handle request error
      console.error("Error editing user:", error);
      toast.error("Failed to update user. Please try again later.");
    }
  };

  const handleExcelDownload = () => {
    const fileName = "ISGGRIP Registration Details.xlsx";

    const dataSubset = filteredUserData.map((user, index) => {
      const time = new Date(`2000-01-01T${user.Time}`);
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedTime = `${formattedHours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${ampm}`;

      const isDuplicateEmail =
        filteredUserData.findIndex(
          (otherUser, otherIndex) =>
            otherUser.email === user.email &&
            otherUser._id !== user._id &&
            otherIndex > index
        ) !== -1;

      const isDuplicatePhone =
        filteredUserData.findIndex(
          (otherUser, otherIndex) =>
            otherUser.mobileNo === user.mobileNo &&
            otherUser._id !== user._id && // Exclude the current user from comparison
            otherIndex > index
        ) !== -1;

      return {
        Date: user.Date,
        Time: formattedTime,
        name: user.name,
        email: user.email,
        place: user.place,
        mobileNo: user.mobileNo,
        Duplicate: (() => {
          if (isDuplicateEmail) return "Duplicate(E)";
          if (isDuplicatePhone) return "Duplicate(P)";
          if (isDuplicateEmail && isDuplicatePhone) return "Duplicate";
          return "";
        })(),
      };
    });

    // Convert the subset of data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(dataSubset);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, fileName);
  };

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    toast.success("Logout Success", { duration: 1000 });
    setTimeout(() => {
      navigate("/");
    }, 50);
  };

  //todays registrations start
  const today = new Date(); // Get current date
  const todayRegistrations = filteredUserData.filter((user) => {
    const parts = user.Date.split("/");
    const userDate = new Date(parts[2], parts[1] - 1, parts[0]); // Note: months are zero-based

    return (
      userDate.getDate() === today.getDate() &&
      userDate.getMonth() === today.getMonth() &&
      userDate.getFullYear() === today.getFullYear()
    );
  });
  const totalTodayRegistrations = todayRegistrations.length;
  //todays registrations end

  //yestarday registrations start
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const yesterdayRegistrations = filteredUserData.filter((user) => {
    const parts = user.Date.split("/");
    const userDate = new Date(parts[2], parts[1] - 1, parts[0]);

    return (
      userDate.getDate() === yesterday.getDate() &&
      userDate.getMonth() === yesterday.getMonth() &&
      userDate.getFullYear() === yesterday.getFullYear()
    );
  });
  const totalYesterdayRegistrations = yesterdayRegistrations.length;
  //yestarday registrations end

  yesterday.setDate(today.getDate() - 1);
  const pastSevenDaysRegistrations = filteredUserData.filter((user) => {
    const parts = user.Date.split("/");
    const userDate = new Date(parts[2], parts[1] - 1, parts[0]);
    const sevenDaysAgo = new Date(yesterday);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
    return userDate >= sevenDaysAgo && userDate <= yesterday;
  });
  const totalpastSevenDaysRegistrations = pastSevenDaysRegistrations.length;

  return (
    <div className="min-h-screen bg-white-400">
      <Toaster />
      <div className="p-4">
        <div className="flex flex-col md:flex-row items-center px-4 py-2 mb-4 rounded-md">
          <div className="flex items-center mb-2 md:mb-0 flex-grow">
            <img
              src={Logo}
              alt="logo"
              className="mr-2"
              width="180px"
              height="180px"
            />
            {/* <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full md:w-100 mr-4 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              /> */}
          </div>

          <div className="flex gap-4 md:gap-8 ">
            {/* <button
                onClick={handleExcelDownload}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Download Excel
              </button> */}
            <button
              onClick={handleLogout}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Logout
            </button>
          </div>
        </div>
        <div class="w-full flex flex-wrap justify-center mb-4">
          <div class="w-62 p-6 m-4 bg-blue-100  sm:w-62 rounded-lg shadow-md mx-auto text-center">
            <h1 class="text-lg font-medium">Total Registrations</h1>
            <p class="text-3xl font-bold">{userData.length}</p>
          </div>
          <div class="w-62 p-6 m-4 bg-green-100  sm:w-62 mx-auto shadow-md rounded-lg text-center">
            <h1 class="text-lg font-medium">Today's Registrations</h1>
            <p class="text-3xl font-bold">{totalTodayRegistrations}</p>
          </div>
          <div class="w-62 p-6 m-4 bg-orange-100  sm:w-62 mx-auto shadow-md rounded-lg text-center">
            <h1 class="text-lg font-medium">Yesterday's Registrations</h1>
            <p class="text-3xl font-bold">{totalYesterdayRegistrations}</p>
          </div>
          <div class="w-62 p-6 m-4 bg-purple-100  sm:w-62 mx-auto shadow-md rounded-lg text-center">
            <h1 class="text-lg font-medium">Registrations Past 7 days</h1>
            <p class="text-3xl font-bold">{totalpastSevenDaysRegistrations}</p>
          </div>
          <div class="w-62 p-6 m-4 bg-red-200  sm:w-62 mx-auto shadow-md rounded-lg text-center">
            <h1 class="text-lg font-medium">No. Duplicates</h1>
            <p class="text-3xl font-bold">{duplicate}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center">
          {/* <div className="flex flex-grow md:w-80 mr-4">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div> */}

          <div className="relative flex flex-grow md:w-80 mr-4">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-2 py-1 pl-8 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </div>

          <button
            onClick={handleExcelDownload}
            className="mt-4 md:mt-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Download Excel
          </button>
        </div>

        <div className="overflow-auto h-72 mt-10">
          <table className="min-w-full divide-y border-2 shadow-2x1 divide-gray-200 text-center">
            <thead className="bg-gray-200 sticky z-20" style={{ top: -1 }}>
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Sr No
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Time
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Dr Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Mobile No
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Place
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Duplicate
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Edit
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xm font-medium text-black-500 uppercase tracking-wider"
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 overflow-auto h-32">
              {filteredUserData.map((user, index) => {
                const time = new Date(`2000-01-01T${user.Time}`);
                const hours = time.getHours();
                const minutes = time.getMinutes();
                const ampm = hours >= 12 ? "PM" : "AM";
                const formattedHours = hours % 12 || 12;
                const formattedTime = `${formattedHours}:${
                  minutes < 10 ? "0" : ""
                }${minutes} ${ampm}`;

                // Check if email or mobile number is duplicate
                const isDuplicateEmail =
                  filteredUserData.findIndex(
                    (otherUser, otherIndex) =>
                      otherUser.email === user.email &&
                      otherUser._id !== user._id && // Exclude the current user from comparison
                      otherIndex > index
                  ) !== -1;

                const isDuplicatePhone =
                  filteredUserData.findIndex(
                    (otherUser, otherIndex) =>
                      otherUser.mobileNo === user.mobileNo &&
                      otherUser._id !== user._id && // Exclude the current user from comparison
                      otherIndex > index
                  ) !== -1;

                {
                  /* const isDuplicate =
                  filteredUserData.findIndex(
                    (otherUser, otherIndex) =>
                     (otherUser.mobileNo === user.mobileNo || otherUser.mobileNo === user.mobileNo) &&
                      otherUser._id !== user._id && // Exclude the current user from comparison
                      otherIndex > index
                  ) !== -1; */
                }

                return (
                  <tr key={user._id} className="hover:bg-gray-100">
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-gray-900">
                        {index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-gray-900">
                        {user.Date}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-gray-900">
                        {formattedTime}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-gray-900">
                        {user.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-gray-900">
                        {user.email}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-gray-900">
                        {user.mobileNo}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-gray-900">
                        {user.place}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      <div className="text-sm font-semibold text-red-500">
                        {isDuplicateEmail
                          ? "Duplicate(E)"
                          : isDuplicatePhone
                          ? "Duplicate(P)"
                          : isDuplicateEmail && isDuplicatePhone
                          ? "Duplicate"
                          : ""}
                        {/* {
                            isDuplicateEmail && isDuplicatePhone
                            ? "Duplicate"
                            : isDuplicateEmail
                            ? "Duplicate(E)"
                            : isDuplicatePhone
                            ? "Duplicate(P)"
                            : ""
                          } */}
                      </div>
                    </td>
                    <td className="pl-10 px-6 py-4 text-center whitespace-nowrap justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="cursor-pointer"
                        onClick={() => openEditDialog(user)}
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                        />
                      </svg>
                    </td>
                    <td className="pl-16 py-4 text-center whitespace-nowrap justify-center items-center">
                      {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash-fill cursor-pointer"
                          viewBox="0 0 16 16"
                          onClick={()=>openDeleteConfirmationModal(user._id)}
                        >
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                        </svg> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash"
                        viewBox="0 0 16 16"
                        onClick={() => openDeleteConfirmationModal(user._id)}
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                    </td>
                    {showDeleteModal && (
                      <div className="fixed z-50 inset-0 flex items-center  opacity-80 justify-center">
                        <div className="bg-white border-4 drop-shadow-lg rounded-xm p-8 max-w-sm mx-auto">
                          <p className="text-lg mb-4 font-bold">
                            Are you sure you want to delete?
                          </p>
                          <div className="flex justify-end">
                            <button
                              className="mr-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                              onClick={() => handleDelete(user)}
                            >
                              Yes
                            </button>
                            <button
                              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md"
                              onClick={closeDeleteConfirmationModal}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <tr>
                      {showEditDialog && (
                        <div className="fixed z-50 inset-0 flex items-center opacity-100 justify-center">
                          <div className="w-full bg-white drop-shadow-lg rounded-lg p-8 max-w-sm mx-auto">
                            <h1 className="text-xl font-bold mb-4">
                              Edit User
                            </h1>
                            <form>
                              <div className="mb-4">
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  value={editUserData.name || ""}
                                  onChange={(e) =>
                                    setEditUserData({
                                      ...editUserData,
                                      name: e.target.value,
                                    })
                                  }
                                  className="mt-1 p-2  border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="email"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  value={editUserData.email || ""}
                                  onChange={(e) =>
                                    setEditUserData({
                                      ...editUserData,
                                      email: e.target.value,
                                    })
                                  }
                                  className="mt-1 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="mobileNo"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Mobile No
                                </label>
                                <input
                                  type="text"
                                  id="mobileNo"
                                  name="mobileNo"
                                  value={editUserData.mobileNo || ""}
                                  onChange={(e) =>
                                    setEditUserData({
                                      ...editUserData,
                                      mobileNo: e.target.value,
                                    })
                                  }
                                  className="mt-1 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="place"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Place
                                </label>
                                <input
                                  type="text"
                                  id="place"
                                  name="place"
                                  value={editUserData.place || ""}
                                  onChange={(e) =>
                                    setEditUserData({
                                      ...editUserData,
                                      place: e.target.value,
                                    })
                                  }
                                  className="mt-1 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                              </div>
                              <div className="flex justify-center">
                                <button
                                  type="button"
                                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
                                  onClick={() => handleEdit(editUserData)}
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                                  onClick={closeEditDialog}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </tr>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const AdminPage = () => {
  const adminToken = localStorage.getItem("adminToken");
  return adminToken ? (
    <Dashboard />
  ) : (
    <div className="text-center">
      You need to be logged in as an admin to view this page.
    </div>
  );
};

export default AdminPage;
